export const displayWithUnitK = (count: number, threshold: number = 10000): string => {
  if (count >= threshold) {
    return `${Number((count / 1000).toFixed(0)).toLocaleString()}K`;
  }
  return count.toLocaleString();
};

export const displayOverThreshold = (
  count: number | undefined,
  threshold: number = 99999
): string => {
  if (!count) {
    return '';
  }
  if (count > threshold) {
    return `${threshold.toLocaleString()}+`;
  }
  return count.toLocaleString();
};

export const formatMoney = (
  inputValue: number,
  decimalCount: number = 2,
  decimal: string = '.',
  thousands: string = ','
): string => {
  try {
    let amount = inputValue;
    if (inputValue > Number.MAX_SAFE_INTEGER) {
      amount = Number.MAX_SAFE_INTEGER;
    }
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    amount = parseFloat(Math.abs(Number(amount) || 0).toFixed(decimalCount));
    const i = parseInt(amount.toString()).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substring(0, j) + thousands : '') +
      i.substring(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - parseFloat(i))
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    return '';
  }
};

export const handleCopyToClipboard = (text: string): void => {
  const tempElement = document.createElement('textarea');
  tempElement.innerText = text;
  document.body.appendChild(tempElement);
  tempElement.select();
  document.execCommand('copy');
  document.body.removeChild(tempElement);
};

export const getNumericPart = (str: string) => {
  const match = str.match(/\d+/);
  return match ? parseInt(match[0], 10) : 0;
};

export const capitalizeFirstLetter = (str: string) => {
  const lowerStr = str.toLowerCase();
  return lowerStr.charAt(0).toUpperCase() + lowerStr.slice(1);
};

export const convertShortFileName = (
  text: string,
  maxLength: number = 23,
  separator: string = '...'
) => {
  const segmenter = new Intl.Segmenter();
  const segments = Array.from(segmenter.segment(text));
  const actualLength = segments.length;

  if (actualLength <= maxLength) {
    return text;
  }

  const extIndex = text.lastIndexOf('.');
  const extension = text.slice(extIndex + 1);
  const namePart = segments.slice(0, extIndex).map((seg: any) => seg.segment);
  const firstPart = namePart
    .slice(0, maxLength - separator.length - (extension.length + 1))
    .join('');

  return `<span>${firstPart}</span>
  <span class="ml-2">${separator}</span>
  <span class="shrink-0">.${extension}</span>`;
};

export const convertShortFileNameWithContainerWidth = (
  text: string,
  containerWidth: number = 0
) => {
  const extIndex = text.lastIndexOf('.');
  const extension = text.slice(extIndex + 1);
  const namePart = text.slice(0, extIndex);

  const tempSpan = document.createElement('span');
  tempSpan.style.cssText =
    'position: absolute; visibility: hidden; white-space: nowrap; font-size: 12px;';
  tempSpan.textContent = namePart;
  document.body.appendChild(tempSpan);

  const textWidth = tempSpan.offsetWidth;
  document.body.removeChild(tempSpan);

  const needTruncate = containerWidth > 0 && textWidth > containerWidth - 50;

  return `<span class="${needTruncate ? 'truncate' : ''}" style="${
    needTruncate ? `width: ${containerWidth - 50}px` : ''
  }">${namePart}</span><span class="shrink-0 ${needTruncate ? '' : ''}">.${extension}</span>`;
};

export const shortenText = (
  text: string,
  maxLength: number,
  endLength: number = 7,
  separator: string = '...'
) => {
  if (text.length <= maxLength) {
    return text;
  }

  const extIndex = text.lastIndexOf('.');
  const extension = text.substring(extIndex - (endLength - (text.length - extIndex)));
  const namePart = text.substring(0, extIndex - (endLength - (text.length - extIndex)));

  const firstPart = namePart.substring(0, maxLength - separator.length - extension.length);

  return firstPart + separator + extension;
};

export const replaceEmptyStringToNull = (params: any): any => {
  if (typeof params === 'string' && params === '') {
    return null;
  }
  if (params.constructor.name === 'Array') {
    const len = params.length;
    for (let i = 0; i < len; i++) {
      const param = params[i];
      if (typeof param === 'string' && param === '') {
        params[i] = null;
      } else if (typeof param === 'object' && param) {
        replaceEmptyStringToNull(params[i]);
      }
    }
  } else if (params.constructor.name === 'Object') {
    for (const [key, value] of Object.entries(params)) {
      if (typeof value === 'string' && value === '') {
        params[key] = null;
      } else if (typeof value === 'object' && value) {
        replaceEmptyStringToNull(params[key]);
      }
    }
  }
  return params;
};

export const getHtmlTextByCompare = (textOne: string, textTwo: string) => {
  return isHTMLUsingParser(textOne) ? textOne : textTwo;
};

export const getRawTextByCompare = (textOne: string, textTwo: string) => {
  return !isHTMLUsingParser(textOne) ? textOne : textTwo;
};

export const htmlToText = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || doc.body.innerText;
};

export const isHTMLUsingParser = (str: string) => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return doc.body.children.length > 0;
};
